interface Config
{
    /** The URL of the this Next.js website. */
    appUrl: string;
    /** The GraphQL API URL.  */
    apiUrl: string;
    /** Is the CDN enabled? */
    $isCdnEnabled: boolean;
    /** The base URL of the AWS CDN. */
    $cdnBaseUrl: string;
    /** The base URL for the /public directory. */
    $publicDirBaseUrl: string;
    /** A list of available languages. */
    languages: AppLanguage[]
    /** The default languge of the app. */
    defaultLanguage: AppLanguage;
    /** The Google Analytics tracking ID. */
    googleAnalyticsID: string;
    /** The Facebook Pixel tracking ID. */
    facebookPixelID: string;
}

let isProduction = process.env.NODE_ENV === 'production';
let isCdnEnabled = parseInt(process.env.NEXT_PUBLIC_CDN_ENABLED as string) == 1;
let languages = [
    { code: 'en', name: 'English' },
    { code: 'ro', name: 'Română' },
];

let config: Config =
{
    appUrl: process.env.NEXT_PUBLIC_APP_URL as string,
    apiUrl: process.env.NEXT_PUBLIC_API_URL as string,
    $isCdnEnabled: isCdnEnabled,
    $cdnBaseUrl: process.env.NEXT_PUBLIC_CDN_BASE_URL as string,
    $publicDirBaseUrl: (isProduction && isCdnEnabled ? process.env.NEXT_PUBLIC_CDN_BASE_URL : process.env.NEXT_PUBLIC_APP_URL) as string,
    languages: languages,
    defaultLanguage: languages.find(l => l.code == 'ro')!,
    googleAnalyticsID: process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID as string,
    facebookPixelID: process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID as string,
}

export type AppLanguage = {
    /** Language code. Eg: en, hu, ro */
    code: string, 
    /** The name of the language in the given language. */
    name: string
};

export default config;