import config, { AppLanguage } from '@config';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from './store';

interface LayoutState
{
    language: AppLanguage;
}

const initialState: LayoutState = {
    language: config.defaultLanguage,
};

export const layoutSlice = createSlice({
    name: 'layout',
    initialState,
    reducers: {
        setAppLanguage: (state, action: PayloadAction<AppLanguage>) => {
            state.language = action.payload;
        }
    }
});

export const { setAppLanguage } = layoutSlice.actions;

export default layoutSlice.reducer;